@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap"); */


/* @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap'); */

@import url("https://fonts.googleapis.com/css2?family=PT+Sans&family=Nunito+Sans&family=Montserrat&family=Poppins:wght@300&=Roboto&display=swap");
@layer components {
    * {
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0, 0, 0, 1);
        box-sizing: border-box;
        transition: 0.03s ease-in;
    }
    .container {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        padding-right: 1rem;
        padding-left: 1rem;
    }
    /* animation */
    .scene {
        position: relative;
        height: calc(100vh - 130px);
        background: linear-gradient(#0d69a5, #0d69a5, #0d69a5, #f1f5f9);
    }
    .sun {
        position: absolute;
        top: 100px;
        left: 55%;
        transform: translateX(-50%);
        width: 100px;
        height: 100px;
        background: #fff;
        border-radius: 50%;
        box-shadow: 0 0 50px hwb(0 100% 0% / 0.925);
        cursor: pointer;
        transition: 0.2s;
    }
    .bg {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 280px;
        background: url("./assets/images/bg.png");
        background-size: 1500px;
        background-repeat: repeat-x;
    }
    .car1 {
        position: absolute;
        left: -200px;
        bottom: 20px;
        max-width: 200px;
        z-index: 10;
        @apply animate-car1;
    }
    .car2 {
        position: absolute;
        left: -400px;
        bottom: 20px;
        max-width: 400px;
        z-index: 10;
        @apply animate-car2;
    }
    .car3 {
        position: absolute;
        right: 100px;
        bottom: -10px;
        max-width: 200px;
        z-index: 10;
    }
    .car3 .suv {
        transform: scaleX(-1);
    }
    .car4 {
        position: absolute;
        right: 00px;
        bottom: 20px;
        max-width: 200px;
        z-index: 10;
        @apply animate-car4;
    }
    .car4 .truck {
        transform: scaleX(-1);
    }
    .animation-delay-2000 {
        animation-delay: 2s;
    }
    .animation-delay-4000 {
        animation-delay: 4s;
    }
    .client-hero-image {
        background-image: linear-gradient( to bottom, rgba(6, 47, 74, 0.8) 0%, rgba(6, 47, 74, 0.8) 100%), url("./assets/images/heroclient.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .bg-dot-1 {
        background-image: url("./assets/images/dot.png");
        background-repeat: no-repeat;
        background-position: left;
        background-size: auto;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 50%;
        width: 20%;
    }
    .bg-dot-2 {
        background-image: url("./assets/images/dot.png");
        background-repeat: no-repeat;
        background-position: left;
        background-size: auto;
        position: absolute;
        right: 0;
        top: 0;
        height: 50%;
        width: 20%;
    }
    .feature-hero-image {
        background-image: linear-gradient( to bottom, rgba(6, 47, 74, 0.8) 0%, rgba(6, 47, 74, 0.8) 100%), url("./assets/images/herofeature.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .conatct-hero-image {
        background-image: linear-gradient( to bottom, rgba(6, 47, 74, 0.8) 0%, rgba(6, 47, 74, 0.8) 100%), url("./assets/images/herocontactus.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}

:root {
    --font-family: "PT Sans", sans-serif;
    --color-primary: #659643;
    --color-tara: #509a2a;
    --color-p: #181717;
}

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
}

code {
    /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;  */
    font-family: "PT Sans", sans-serif;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    font-family: "Nunito sans", sans-serif !important;
    /* Graphik,sans-serif */
    /* "PT Sans", sans-serif */
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif */
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    background: var(--color-bg);
}

a {
    color: unset;
    outline: unset;
    text-decoration: none !important;
}


/* Define the counter style */


/* "\1F34E" "\1F34F" " "\1F44D" "\1FAF1" */

@counter-style finger-pointer {
    system: cyclic;
    symbols: "\1F449";
    suffix: " ";
}

ul.my-list-style {
    list-style-type: finger-pointer;
}